exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-index-jsx": () => import("./../../../src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-services-birth-prep-prenatal-pt-index-jsx": () => import("./../../../src/pages/services/birth-prep-prenatal-pt/index.jsx" /* webpackChunkName: "component---src-pages-services-birth-prep-prenatal-pt-index-jsx" */),
  "component---src-pages-services-orthopedic-pt-index-jsx": () => import("./../../../src/pages/services/orthopedic-pt/index.jsx" /* webpackChunkName: "component---src-pages-services-orthopedic-pt-index-jsx" */),
  "component---src-pages-services-pelvic-health-index-jsx": () => import("./../../../src/pages/services/pelvic-health/index.jsx" /* webpackChunkName: "component---src-pages-services-pelvic-health-index-jsx" */),
  "component---src-pages-services-post-surgical-pt-index-jsx": () => import("./../../../src/pages/services/post-surgical-pt/index.jsx" /* webpackChunkName: "component---src-pages-services-post-surgical-pt-index-jsx" */),
  "component---src-pages-services-postpartum-recovery-index-jsx": () => import("./../../../src/pages/services/postpartum-recovery/index.jsx" /* webpackChunkName: "component---src-pages-services-postpartum-recovery-index-jsx" */),
  "component---src-pages-testimonials-index-jsx": () => import("./../../../src/pages/testimonials/index.jsx" /* webpackChunkName: "component---src-pages-testimonials-index-jsx" */)
}

