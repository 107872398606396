module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Resilience Physical Therapy & Wellness","short_name":"Resilience PT","description":"Relief from pregnancy or postpartum issues, pelvic pain or other pelvic health concerns. Serving Carbondale to Rifle, Colorado","start_url":"/","background_color":"#ffffff","theme_color":"#4DB6AC","display":"minimal-ui","icon":"content/assets/favicon.png","shortcuts":[{"name":"Contact","description":"Open the contact page","url":"/contact","icons":[{"src":"assets/icons/contact-us-shortcut.png","sizes":"64x64"}]}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0a51e75652e7db8bebe364be916cb420"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Poppins:400,600,700"]}},
    }]
